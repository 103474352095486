/**
 * Estilos Globais para Componentes
 */

/* Estilos de Navegação */
.main-navigation {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.nav-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
}

.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #333;
}

.nav-logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 10px;
}

.logo-text {
  font-size: 18px;
  font-weight: bold;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-link {
  text-decoration: none;
  color: #555;
  font-weight: 500;
  padding: 8px 12px;
  border-radius: 4px;
  transition: all 0.3s;
}

.nav-link:hover {
  background-color: #f5f5f5;
  color: #3D8361;
}

.nav-link.active {
  background-color: #EEF2E6;
  color: #3D8361;
}

.logout-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

/* Estilos do Calendário */
.calendar {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 700px;
  margin: 0 auto;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.calendar-header h3 {
  font-size: 18px;
  margin: 0;
  color: #333;
}

.month-nav {
  background-color: #f5f5f5;
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: #555;
  transition: background-color 0.3s;
}

.month-nav:hover {
  background-color: #e0e0e0;
}

.calendar-days-header {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
  margin-bottom: 10px;
}

.weekday {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  padding: 5px 0;
  color: #666;
}

.calendar-days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 5px;
}

.calendar-day {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 50%;
  font-size: 14px;
  cursor: default;
  position: relative;
}

.calendar-day.day {
  cursor: pointer;
  color: #999;
}

.calendar-day.available {
  color: #333;
  background-color: #EEF2E6;
  font-weight: bold;
}

.calendar-day.available:hover {
  background-color: #D0E7D2;
}

.calendar-day.selected {
  background-color: #3D8361;
  color: white;
}

.calendar-day.empty {
  cursor: default;
}

/* Adicionar estilos para novos elementos */

/* Calendário */
.calendar-loading {
  text-align: center;
  padding: 10px 0;
  font-style: italic;
  color: #666;
}

.calendar-error {
  text-align: center;
  padding: 10px 0;
  color: #d9534f;
  font-weight: bold;
}

.calendar-legend {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 15px;
}

.legend-item {
  display: flex;
  align-items: center;
  font-size: 13px;
}

.legend-color {
  width: 15px;
  height: 15px;
  border: 1px solid #ddd;
  margin-right: 5px;
  border-radius: 3px;
}

.legend-color.available {
  background-color: #8fbc8f;
}

.legend-color.selected {
  background-color: #3D8361;
}

/* Formulário de Reserva */
.reservation-summary {
  background-color: #f9f9f9;
  border-radius: 6px;
  padding: 15px;
  margin: 20px 0;
  border-left: 4px solid #3D8361;
}

.total-value {
  font-size: 18px;
  color: #3D8361;
  margin-top: 10px;
  margin-bottom: 0;
}

.loading-indicator {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
  padding: 15px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 8px;
}

.loading-spinner {
  width: 40px;
  height: 40px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3D8361;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.error-message {
  display: block;
  color: #d9534f;
  font-size: 13px;
  margin-top: 5px;
}

input.error, textarea.error {
  border-color: #d9534f;
}

.reservation-id {
  margin: 20px 0;
  padding: 10px;
  background-color: #eef2e6;
  border-radius: 5px;
  font-size: 16px;
  color: #333;
}

/* Estilos do Formulário de Reserva */
.reservation-form {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 25px;
  max-width: 700px;
  margin: 30px auto;
}

.reservation-form h3 {
  margin-top: 0;
  margin-bottom: 20px;
  color: #333;
  font-size: 20px;
}

.selected-dates {
  margin-bottom: 20px;
  padding: 12px;
  background-color: #EEF2E6;
  border-radius: 6px;
  font-size: 14px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
  color: #333;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #3D8361;
  outline: none;
}

.form-group input.error,
.form-group textarea.error {
  border-color: #e53935;
}

.form-buttons {
  display: flex;
  justify-content: flex-end;
  gap: 15px;
  margin-top: 30px;
}

.button {
  padding: 12px 20px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s;
}

.primary-button {
  background-color: #3D8361;
  color: white;
}

.primary-button:hover {
  background-color: #346A4E;
}

.secondary-button {
  background-color: #e0e0e0;
  color: #333;
}

.secondary-button:hover {
  background-color: #d0d0d0;
}

/* Layout para páginas */
.page-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 30px 20px;
}

.page-title {
  font-size: 28px;
  color: #333;
  margin-top: 0;
  margin-bottom: 30px;
  text-align: center;
}

.page-description {
  text-align: center;
  color: #666;
  margin-bottom: 30px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
}

/* Layout de duas colunas */
.two-column-layout {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 30px;
  margin-top: 40px;
}

/* Layout de destaque */
.feature-section {
  background-color: #EEF2E6;
  padding: 40px 20px;
  margin: 40px 0;
  border-radius: 10px;
}

.feature-title {
  text-align: center;
  font-size: 24px;
  color: #3D8361;
  margin-bottom: 20px;
}

.feature-description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto 30px;
  color: #555;
  line-height: 1.6;
}

/* Responsividade */
@media (max-width: 768px) {
  .two-column-layout {
    grid-template-columns: 1fr;
  }
  
  .nav-container {
    flex-direction: column;
    gap: 15px;
  }
  
  .nav-links {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.field-tip {
  display: block;
  color: #777;
  font-size: 12px;
  margin-top: 5px;
  font-style: italic;
} 